<template>
  <div class="mt-8 h-max sm:mx-auto sm:w-full">
    <div class="bg-primary text-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <div class="mb-5" v-if="title">
        <p class="text-xl font-semibold text-center">
          {{ title }}
        </p>
      </div>
      <form class="space-y-6">
        <div>
          <div class="text-center text-sm font-light">IDENTITÉ</div>
          <div class="bg-white h-0.5 w-4/5 mx-auto my-4"></div>
          <div class="flex justify-center">
            <div class="flex flex-col gap-4">
              <div class="flex flex-col sm:flex-row gap-2">
                <TextField
                  v-model="lastname"
                  :isDisabled="fields.lastname && fields.lastname.locked"
                  id="lastname"
                  type="text"
                  label="Nom"
                  :isRequired="true"
                  :isColorLight="true"
                ></TextField>
                <TextField
                  v-model="firstname"
                  :isDisabled="fields.firstname && fields.firstname.locked"
                  id="firstname"
                  type="text"
                  label="Prénom"
                  :isRequired="true"
                  :isColorLight="true"
                ></TextField>
              </div>
              <div class="flex flex-col sm:flex-row gap-2">
                <TextField
                  v-model="birthdate"
                  :isDisabled="fields.birthdate && fields.birthdate.locked"
                  id="birthdate"
                  type="date"
                  label="Date de naissance"
                  invalidText="Veuillez entrer une date valide."
                  :isRequired="true"
                  :isColorLight="true"
                ></TextField>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="text-center text-sm font-light">COORDONNÉES</div>
          <div class="bg-white h-0.5 w-4/5 mx-auto my-4"></div>
          <div class="flex justify-center">
            <div class="flex flex-col gap-4">
              <div class="flex flex-col sm:flex-row gap-2">
                <TextField
                  v-model="address"
                  :isDisabled="fields.address && fields.address.locked"
                  id="address"
                  type="text"
                  label="Adresse"
                  :isRequired="true"
                  :isColorLight="true"
                ></TextField>
                <TextField
                  v-model="city"
                  :isDisabled="fields.city && fields.city.locked"
                  id="city"
                  type="text"
                  label="Ville"
                  :isRequired="true"
                  :isColorLight="true"
                ></TextField>
              </div>
              <div class="flex flex-col sm:flex-row gap-2">
                <TextField
                  v-model="zipcode"
                  :isDisabled="fields.zipcode && fields.zipcode.locked"
                  id="zipcode"
                  type="text"
                  label="Code postal"
                  :isRequired="true"
                  :isColorLight="true"
                ></TextField>
                <TextField
                  v-model="country"
                  :isDisabled="fields.country && fields.country.locked"
                  id="country"
                  type="text"
                  label="Pays"
                  :isRequired="true"
                  :isColorLight="true"
                ></TextField>
              </div>
            </div>
          </div>
          <div class="flex justify-center">
            <div class="flex flex-col sm:flex-row gap-4 justify-center mt-8">
              <TextField
                v-model="phone"
                :value="phone"
                :isDisabled="fields.phone && fields.phone.locked"
                id="phone"
                type="tel"
                label="Téléphone"
                :isRequired="true"
                :isColorLight="true"
              ></TextField>
              <TextField
                v-model="registerEmail"
                :isDisabled="fields.registerEmail && fields.registerEmail.locked"
                id="register-email"
                type="email"
                label="Adresse E-mail"
                helpText="Promis pas de spam !"
                :isRequired="true"
                :isColorLight="true"
              ></TextField>
            </div>
          </div>
          <div class="flex justify-center">
            <div class="flex flex-col sm:flex-row gap-4 justify-center mt-8">
              <TextField
                v-model="password"
                :isDisabled="fields.password && fields.password.locked"
                id="password"
                type="password"
                label="Mot de passe"
                helpText="doit contenir au moins 1 majuscule, 1 minuscule, 1 chiffre, et faire au moins 8 charactères de long"
                :isRequired="true"
                :isColorLight="true"
              ></TextField>
              <TextField
                v-model="passwordConfirm"
                :isDisabled="fields.passwordConfirm && fields.passwordConfirm.locked"
                id="passwordConfirm"
                type="password"
                label="Confirmation de mot de passe"
                :isRequired="true"
                :isColorLight="true"
              ></TextField>
            </div>
          </div>
        </div>

        <Checkbox
          v-model="isAdultChecked"
          :isDisabled="fields.isAdultChecked && fields.isAdultChecked.locked"
          id="isAdultChecked"
          :isRequired="true"
          title="Je certifie que le bénéficiaire de l'abonnement est majeur"
          :isColorLight="true"
        />
        <Checkbox
          v-model="hasAcceptedCGVchecked"
          :isDisabled="fields.hasAcceptedCGVchecked && fields.hasAcceptedCGVchecked.locked"
          id="hasAcceptedCGVchecked"
          :isRequired="true"
          title="J'ai lu et j'accepte les Conditions Générales de Vente"
          :isColorLight="true"
        />
        <Checkbox
          v-model="newslettersApproved"
          :value="newslettersApproved"
          :isDisabled="fields.newslettersApproved && fields.newslettersApproved.locked"
          id="newslettersApproved"
          title="J'accepte de recevoir des mails"
          :isColorLight="true"
        />

        <div>
          <Button @clicked="register" :loading="loading"> S'inscrire </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { useAppStateStore } from '@/stores/appState'
import { useToastStore } from '@/stores/toast'
import Button from '@/components/Button'
import TextField from '@/components/TextField'
import Checkbox from '@/components/Checkbox'

import authenticationApi from '@/api/authentication'

export default {
  name: 'RegisterForm',
  components: {
    Button,
    TextField,
    Checkbox
  },
  props: {
    title: {
      type: String
    },
    fields: {
      type: Object,
      default: () => ({})
    },
    accountActivationAuthorization: {
      type: String
    },
    isUpdate: {
      type: Boolean
    }
  },
  data() {
    return {
      loading: false,
      password: '',
      passwordConfirm: '',
      isAdultChecked: false,
      hasAcceptedCGVchecked: false,
      firstname: '',
      lastname: '',
      birthdate: '',
      address: '',
      zipcode: '',
      city: '',
      country: '',
      phone: null,
      registerEmail: '',
      newslettersApproved: false
    }
  },
  mounted() {
    this.scrollToTop()
  },
  methods: {
    ...mapActions(useToastStore, ['showToast']),
    ...mapActions(useAppStateStore, ['updateUser']),
    async register() {
      if (
        !this.password ||
        !this.passwordConfirm ||
        !this.firstname ||
        !this.lastname ||
        !this.birthdate ||
        !this.address ||
        !this.zipcode ||
        !this.country ||
        !this.phone ||
        !this.registerEmail ||
        !this.passwordConfirm
      ) {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Veuillez renseigner tous les champs requis'
        })
        return
      }
      if (!this.phone.countryCallingCode || !this.phone.nationalNumber) {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Le numéro de téléphone que vous avez renseigné est invalide'
        })
        return
      }
      if (!this.isAdultChecked || !this.hasAcceptedCGVchecked) {
        this.showToast({
          error: true,
          title: 'Erreur',
          message:
            "Veuillez certifier que le bénéficiaire de l'abonnement est majeur et accepter les conditions générales de vente"
        })
        return
      }
      if (this.loading) return
      this.loading = true
      try {
        let response
        if (this.accountActivationAuthorization) {
          response = await authenticationApi.activateAccount(
            {
              birthDate: this.birthdate,
              address: this.address,
              city: this.city,
              postalCode: this.zipcode,
              country: this.country,
              phoneCountryIndicator: this.phone.countryCallingCode,
              phoneNumber: this.phone.nationalNumber,
              password: this.password,
              passwordConfirm: this.passwordConfirm,
              newslettersApproved: this.newslettersApproved
            },
            this.accountActivationAuthorization
          )
        } else {
          const body = {
            firstName: this.firstname,
            lastName: this.lastname,
            birthDate: this.birthdate,
            address: this.address,
            city: this.city,
            postalCode: this.zipcode,
            country: this.country,
            phoneCountryIndicator: this.phone.countryCallingCode,
            phoneNumber: this.phone.nationalNumber,
            email: this.registerEmail,
            password: this.password,
            passwordConfirm: this.passwordConfirm,
            newslettersApproved: this.newslettersApproved
          }
          response = await (this.isUpdate ? authenticationApi.update : authenticationApi.register)(body)
        }
        if (response.data.token) {
          localStorage.setItem('Authorization', response.data.token)
        }
        delete response.data.token
        this.updateUser(response.data)
        this.$emit('success')
      } catch (err) {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: err.response.data.message
        })
        console.error(err)
      }
      this.loading = false
    },

    scrollToTop() {
      window.scrollTo(0, 0)
    }
  },

  watch: {
    fields: {
      handler() {
        for (const [fieldName, field] of Object.entries(this.fields)) {
          this[fieldName] = field.value
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style src="vue3-tel-input/dist/vue3-tel-input.css"></style>
